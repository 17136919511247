import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Bank from "../../components/kyc/Bank";
import BillingAddressKyc from "../../components/kyc/BillingAddressKyc";
import Pan from "../../components/kyc/Pan";
// import BankLogo from "../../images/bank_account_icon.png";
// import BillingLogo from "../../images/billing_logo.png";
// import Panlogo from "../../images/profile.png";
import { getkycDetailsApi } from "../../redux/features/kycDetailsSlice";
// import ifsc from 'ifsc'
import { FaRegCheckCircle } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { getProfileApi } from "../../redux/features/profileSlice";

const KYC = () => {
  let allpan = "";

  const { t } = useTranslation();
  const { panDetails, billingAddress, bankAccDetails, adhaarDetails } = t(
    "profileSettingPage.kycTab"
  );

  const Token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  // ----------------------state-------------------------------------
  const [show, setShow] = useState(false);
  const [panall, setpanall] = useState("");
  const [pannum, setpannum] = useState("");
  const [Dob, setDOB] = useState("");
  const [Email, setEmail] = useState("");

  // ------------------------Adhar data------------------
  const [AName, setAName] = useState("");
  const [Anumber, setANumber] = useState("");
  // ----------------------------------

  // ---------------------bank details--------------------------
  const [bankName, setBankName] = useState("");
  const [AccNumber, setAccNumber] = useState("");
  const [IFSC, setIFSC] = useState("");

  const [bankDetails, setBankDetails] = useState({
    accHolderName: "",
    accNum: "",
    IFSC: "",
  });

  // -----------------------------billing data--------------------
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [Sata, setState] = useState("");
  const [City, setCity] = useState("");
  const [Zip, setPinCode] = useState("");
  const [mobile, SetMobile] = useState("");
  const [fName, setfName] = useState("");

  const [checkaddress, setcheckaddress] = useState([]);
  useEffect(() => {
    GetDetailsAPI();
    // GetProfileAPI();
    GetAddressKycAPI();
  }, []);

  // ------------------------------------------get details api start---------------------------
  const GetDetailsAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/kyc/getDetails`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });

      const panResponse = response.data.data[0].pan.msg;
      const bankResponse = response?.data?.data[0]?.bank?.msg;

      let allpan = response.data.data[0].pan.msg;

      let allBank = response.data.data[0].bank.msg;
      let newVal = response.data.data[0];

      setBankDetails({
        accHolderName: bankResponse.name,
        accNum: bankResponse.account_no,
        IFSC: bankResponse.ifsc,
      });

      setpannum(allpan.PanNumber);
      setDOB(allpan.dob);
      setEmail(allpan.email);
      // -----------------------------------billing data-----------------
      const allAadhaar = response.data.data[0].adhar.msg;
      setAName(allAadhaar.Name);
      setANumber(allAadhaar.Aadhar_No);

      // -------------------------account details-----------------------------
      setBankName(bankResponse);
      setAccNumber(allBank.account_no);
      setIFSC(allBank.ifsc);
    } catch (error) {
      console.error("Error connecting to API in getprofile function", error);
    }
  };

  const [addressApiData, setAddressApiData] = useState("");
  const GetAddressKycAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/address/get_address`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();

      const { name, line1, line2, state, city, zip, mobileNumber } =
        data?.data[data.data.length - 1] || [];

      setAddressApiData(zip);
    } catch (error) {
      console.error("ERROR IN CATCH+++++++++++++++++", error);
    }
  };


  // =========================================================
  const kycDetailApiResponse = useSelector(
    (state) => state.kycDetailsSlice.data.data
  );

  const { PanNumber, dob, email, Name, NameOnTheCard, STATUS } =
    kycDetailApiResponse?.[0]?.pan?.msg || {};
  const { account_no } = kycDetailApiResponse?.[0]?.bank?.msg || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getkycDetailsApi());
  }, [dispatch]);

  // ================================================================================

  const [expanded, setExpanded] = useState("panel1");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNext = (nextPanel) => {
    setExpanded(nextPanel);
  };

  // =================================================================

  const profileApiResponse = useSelector(
    (state) => state.profileSlice.data.data
  );

  useEffect(() => {
    dispatch(getProfileApi());
  }, []);

  return (
    <>
      <section id="KYC">
        <div>
          <div className="row">
            {/* PAN DETAILS Accordion */}
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
              className="mb-4"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src="/images/profile.png" width={40} />
                    <span className="p-2">{panDetails}</span>
                  </div>
                  <div>
                    {PanNumber ? (
                      <FaRegCheckCircle color="green" size={30} />
                    ) : (
                      <MdErrorOutline color="red" size={30} />
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Pan
                  kycDetailApiResponse={kycDetailApiResponse}
                  handleNext={handleNext}
                />
              </AccordionDetails>
            </Accordion>
            {/* BILLING ADDRESS Accordion */}
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleAccordionChange("panel2")}
              className="mb-4"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src="/images/billing_logo.png" width={40} />
                    <span className="p-2">{billingAddress}</span>
                  </div>
                  <div>
                    {addressApiData ? (
                      <FaRegCheckCircle color="green" size={30} />
                    ) : (
                      <MdErrorOutline color="red" size={30} />
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <BillingAddressKyc
                  handleNext={handleNext}
                  GetAddressKycAPI={GetAddressKycAPI}
                />
              </AccordionDetails>
            </Accordion>
            {/* BANK DETAILS Accordion */}
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleAccordionChange("panel3")}
              className="mb-4"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src="/images/bank_account_icon.png" width={40} />
                    <span className="p-2">{bankAccDetails} </span>
                  </div>
                  <div>
                    {account_no ? (
                      <FaRegCheckCircle color="green" size={30} />
                    ) : (
                      <MdErrorOutline color="red" size={30} />
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Bank kycDetailApiResponse={kycDetailApiResponse} />
              </AccordionDetails>
            </Accordion>
          </div>

          {/* <Modal show={show} onHide={handleClose}>
            <h5 className="text-center p-2" style={{ color: "#4A2A7D" }}>
              How to Find My Aadhaar Number
            </h5>
            <img src={AdharInfo} className="img-fluid" />
            <div className="d-grid gap-1">
              <Button
                className="btn text-light mb-4"
                style={{ backgroundColor: "#4A2A7D" }}
                onClick={handleClose}
              >
                Got It
              </Button>
            </div>
          </Modal> */}
        </div>
      </section>
    </>
  );
};

export default KYC;

/* <div className="card p-4">
                  <form onSubmit="">
                    <div className="mb-3">
                      <label htmlFor="PAN_Number" className="form-label">
                        PAN Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter PAN number here"
                        value={pannum}
                        name="name"
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="dob" className="form-label">
                        Date Of Birth
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="dob"
                        value={Dob}
                        name="dob"
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="email"
                        value={Email}
                        name="email"
                        readOnly
                      />
                    </div>
                  </form>
                </div> */

// *****************************************************************************

/* <div className="card p-3 PAN_DETAILS_SHADOW">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Account Holder's Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bankname"
                        placeholder="name"
                        value={bankDetails.accHolderName}
                        name="bankname"
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Bank Account Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Accnumber"
                        placeholder="Bank Account Number"
                        value={bankDetails.accNum}
                        name="Accnumber"
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Bank IFSC Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Bank IFSC Code"
                        value={bankDetails.IFSC}
                        // onChange={BankAccountDetailsInput}
                        name="IFSC"
                        readOnly
                      />
                    </div>
                  </form>
                </div> */

// ********************************************************************

/* <Accordion className="mb-4">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <img src={AdharLogo} width={40} />
                <span className="p-2">{adhaarDetails}</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="card p-3 PAN_DETAILS_SHADOW">
                  <form>
                    <div className="mb-3">
                      <label htmlFor="adhaar_number" className="form-label">
                        Aadhaar Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="adhaar_number"
                        placeholder="Aadhaar Number"
                        value={Anumber}
                        readOnly
                      />
                    </div>

                    <button
                      type="button"
                      className="btn btn-red px-3"
                      style={{
                        backgroundColor: "#4A2A7D",
                        color: "white",
                        float: "left",
                      }}
                      onClick={handleShow}
                    >
                      How to Find My Adhaar Number
                    </button>
                  </form>
                </div>
              </AccordionDetails>
            </Accordion> */
