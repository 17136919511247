import React, { useState, useEffect } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { GoArrowLeft } from "react-icons/go";
import { toast } from "react-toastify";
import Footer from "../../components/Footer.jsx";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader.jsx";
import { Button } from "@mui/material";
const Swal = require("sweetalert2");

function SilverShell() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const { t } = useTranslation();
  const { orderSummary, totalAmt, priceExpire } = t("goldOrderSummaryPage");
  const { silverWeight, silverRate, silverValue } = t(
    "goldOrderSummaryPage.silverSummary"
  );

  const { sellBtn } = t("goldSellSummaryPage");

  const [acc, setAcc] = useState("");
  const [gold, setGold] = useState("");
  const data = location.state;

  const Goldweight = data.data.data[0].totalQuantity;
  const Goldrate = data.data.data[0].pricePerGram;
  const GoldValue = data.data.data[0].amount;
  const GST = data.data.data[0].gst;
  const TotalAmount = data.data.data[0].amountAfterTax;
  const mtd = data.data.data[0].merchantTransactionId;
  const mt = data.data.data[0].metalType;
  const mClick = data.clickFirst;

  const Token = sessionStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  const callGetBuyAPI = async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/augmont/sell-gold-silver`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const requestBody = {
        metalType: mt,
        quantity: Goldweight,
      };

      if (mClick === "A") {
        requestBody["amount"] = TotalAmount;
      } else if (mClick === "Q") {
        requestBody["quantity"] = Goldweight;
      } else {
        requestBody["amount"] = TotalAmount;
      }

      const response = await axios.post(url, requestBody, { headers });
      const transactionId = response.data.data[0].result.data.transactionId;
      setLoading(false);

      Swal.fire({
        title: "Sold Successful",
        html: `
        Transaction Number: ${transactionId}<br/><br/>
        Amount: ₹${TotalAmount}
      `,
        icon: "success",
        confirmButtonColor: "#4A2A7D",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((d) => {
        navigate("/dashboard/silverSoldInvoice", {
          state: {
            rateofgold: Goldrate,
            Goldweight,
            GST,
            Totalamount: TotalAmount,
            transectionID: transactionId,
            UniqueId: mtd,
            inoviceno: mt,
            invoicepath: response.data.data[0].invoicePath,
          },
        });
      });
    } catch (error) {
      setLoading(false);
      console.log("ERROR IN CATCH++++++++++++", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetProfileAPI();
  }, []);

  const [profileApiData, setProfileApiData] = useState("");

  const GetProfileAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/getProfile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      setProfileApiData(response?.data?.data[0]);

      const accounttt = response.data.data[0].kyc.account_no;
      setAcc(accounttt);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const goto = () => {
    if (acc === null) {
      navigate("/dashboard/bankdetails");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#4A2A7D",
        cancelButtonColor: "#d33",
        confirmButtonText: "Proceed to sell !",
      })
        .then((d) => {
          if (d.isConfirmed === true) {
            callGetBuyAPI();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const handlePayment = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
      confirmButtonText: "Proceed to sell !",
    })
      .then((d) => {
        if (d.isConfirmed === true) {
          callGetBuyAPI();
          // const payload = {
          //   metalType: mt,
          //   quantity: Goldweight,
          // };

          // dispatch(goldAndSilverSellSlice(payload));
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const renderTime = ({ remainingTime }) => {
    return <div>{remainingTime}</div>;
  };

  return (
    <div style={{ height: "auto", width: "100%" }}>
      {loading ? (
        <div style={{ height: "90vh" }}>
          <Loader />
        </div>
      ) : (
        <div className="row d-flex justify-content-between mt-0">
          <div
            style={{
              height: "auto",
              borderRadius: "10px",
              boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
            }}
          >
            <h2
              className="m-2 fw-bold text-center mb-3"
              style={{ color: "#4A2A7D" }}
            >
              {orderSummary}
            </h2>

            <div className="row">
              <table className="table table-borderless">
                <tbody style={{ color: "#4A2A7D" }}>
                  <tr>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      Silver Weight AUGMONT (gm)
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {Goldweight}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {silverRate}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {Goldrate}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {silverValue} {Goldweight}/gm (&#8377;)
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#4A2A7D", fontSize: "20px" }}
                      className=" fs-5"
                    >
                      {TotalAmount}
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
                      {totalAmt} (&#8377;)
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
                      {TotalAmount}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              className="d-flex justify-content-center mb-3"
              style={{
                alignItems: "center",
                textAlign: "center",
                display: "flex",
                gap: "5px",
              }}
            >
              <span className=" mt-3 mr-3 h5" style={{ color: "#4A2A7D" }}>
                {priceExpire}
              </span>{" "}
              <CountdownCircleTimer
                isPlaying
                size={60}
                strokeWidth={10}
                duration={180} // 3 minutes in seconds
                colors={["#4A2A7D", "#4A2A7D", "#4A2A7D", "#4A2A7D"]}
                colorsTime={[180, 120, 60, 0]}
                onComplete={() => {
                  navigate(-1); // Redirect to the previous history page
                  return { shouldRepeat: false }; // Ensure the timer doesn't repeat
                }}
              >
                {({ remainingTime }) => {
                  const minutes = Math.floor(remainingTime / 60);
                  const seconds = remainingTime % 60;
                  const formattedTime = `${minutes}:${seconds
                    .toString()
                    .padStart(2, "0")}`;
                  return (
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                      {formattedTime}
                    </div>
                  );
                }}
              </CountdownCircleTimer>
            </div>

            <Footer />

            {/* <div className="d-flex justify-content-center mt-4 mb-2 ">
              <button
                type="button"
                onClick={() => goto()}
                style={{
                  borderRadius: "20px",
                  fontSize: "20px",
                  backgroundColor: "#4A2A7D",
                  color: "white",
                  padding: "5px 50px",
                }}
              >
                {sellBtn}
              </button>
            </div> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "2rem auto",
              }}
            >
              {profileApiData?.kyc_status == 1 &&
              profileApiData?.kyc.account_no ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#4a2a7d",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#4a2a7d",
                    },
                  }}
                  onClick={handlePayment}
                >
                  Sell now
                </Button>
              ) : (
                <Link to="/dashboard/ProfileSetting">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#4a2a7d",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#4a2a7d",
                      },
                    }}
                  >
                    Complete KYC
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      )}
    </div>
  );
}

export default SilverShell;

// import React, { useState } from "react";
// import { CountdownCircleTimer } from "react-countdown-circle-timer";
// import { Link, useLocation } from "react-router-dom";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useCountdown } from "react-countdown-circle-timer";
// import axios from "axios";
// const Swal = require("sweetalert2");

// function SilverShell() {
//   useEffect(() => {
//     const login = sessionStorage.getItem("token");
//     if (!login) {
//       navigate("/");
//     }
//   }, []);

//   const [acc, setacc] = useState("");

//   // ----------------timer--------------
//   const {
//     path,
//     pathLength,
//     stroke,
//     strokeDashoffset,
//     remainingTime,
//     elapsedTime,
//     size,
//     strokeWidth,
//   } = useCountdown({ isPlaying: true, duration: 7, colors: "#abc" });

//   const renderTime = ({ remainingTime }) => {
//     const minutes = Math.floor(remainingTime / 60);
//     const seconds = remainingTime % 60;

//     return (
//       <div className="timer text-dark">
//         <div className="value" style={{ color: "#4A2A7D", fontSize: "14px" }}>
//           {minutes}:{seconds < 10 ? "0" : ""}
//           {seconds}
//         </div>
//       </div>
//     );
//   };
//   // -----------------------------------------------

//   const Token = sessionStorage.getItem("token");
//   // console.log("login token in inbox", Token);

//   const navigate = useNavigate();
//   const location = useLocation();
//   const [gold, SetGold] = useState("");
//   const data = location.state;
//   console.log("ordersummary data", data);

//   const Goldweight = data.data.data[0].totalQuantity;
//   const Goldrate = data.data.data[0].pricePerGram;
//   console.log("Goldrate", Goldrate);
//   // SetGold(Goldrate);
//   const GoldValue = data.data.data[0].amount;
//   const GST = data.data.data[0].gst;
//   const TotalAmount = data.data.data[0].amountAfterTax;
//   console.log("TotalAmount", TotalAmount);
//   const mtd = data.data.data[0].merchantTransactionId;
//   console.log("mtd", mtd);
//   const mt = data.data.data[0].metalType;
//   console.log("mt", mt);
//   const mClick = data.clickFirst;

//   console.log("mClick");

//   // -------------------------sell api -----------------------

//   // ----------------------------------
//   const callGetBuyAPI = async () => {
//     try {
//       const url = `${process.env.REACT_APP_BASE_URL}/augmont/sell-gold-silver`;

//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       // console.log("hiiiiiii");

//       const requestBody = {
//         // merchantTransactionId: mtd,
//         metalType: mt,
//         // quantity: "0.0002",
//         quantity: { Goldweight },
//       };

//       if (mClick === "A") {
//         requestBody["amount"] = TotalAmount;
//       } else {
//         requestBody["quantity"] = Goldweight;
//       }

//       const response = await axios.post(url, requestBody, { headers });

//       Swal.fire({
//         title: "Sold Successful",
//         html: `
//                 Transaction Number: ${response.data.data[0].result.data.transactionId}<br/><br/>
//                 Amount: ${TotalAmount}
//               `,
//         icon: "success",
//         confirmButtonColor: "#4A2A7D",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Ok",
//       }).then((d) => {
//         navigate("/dashboard/goldSoldInvoice");
//         // navigate("/dashboard/portfolio");
//       });

//       var data = response.data;
//       // setShowSuccessPopup(true); // Show success popup after API call succeeds
//     } catch (error) {
//       // console.error("Error connecting to API", error);
//     }
//   };

//   useEffect(() => {
//     GetProfileAPI();
//   }, []);

//   // ------------------------get Profile for KYC ------------------------------------
//   const GetProfileAPI = async () => {
//     try {
//       const url = `${process.env.REACT_APP_BASE_URL}/getProfile`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const accounttt = response.data.data[0].kyc.account_no;
//       setacc(accounttt);
//     } catch (error) {
//       // console.error("Error connecting to API", error);
//     }
//   };
//   // -----------------------end ------------------------------------------

//   const goto = () => {
//     // console.log("accountnoooooo", acc)
//     if (acc === null) {
//       navigate("/dashboard/silverbankdetails");
//     } else {
//       // callGetBuyAPI();

//       Swal.fire({
//         title: "Are you sure?",
//         text: "You won't be able to revert this!",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#880E4F",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Proceed to sell !",
//       })
//         .then((d) => {
//           if (d.isConfirmed === true) {
//             callGetBuyAPI();
//             navigate("/dashboard/history", {});
//           }
//         })
//         .catch((error) => {
//           // console.log("error", error);
//         });
//     }
//   };

//   return (
//     <div style={{ height: "auto" }}>

//       <div className="row d-flex justify-content-between">

//         <div
//           //  className="col-md-6  "
//           style={{
//             height: "auto",
//             borderRadius: "10px",
//             boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
//           }}
//         >
//           <h2
//             className="m-2 fw-bold text-center mb-3"
//             style={{ color: "#4A2A7D" }}
//           >
//             Order Summary
//           </h2>

//           <div className="row">
//             <table class="table table-borderless">
//               <tbody style={{ color: "#880E4F" }}>
//                 <tr>
//                   <td></td>

//                   <td>Silver Weight AUGMONT (g)</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td className="mr-5">{Goldweight}</td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td>Silver Rate per gram (&#8377;)</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>{Goldrate}</td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td>Silver Value Of 1.0000/gm (&#8377;)</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>{GoldValue}</td>
//                 </tr>

//                 <tr>
//                   <td></td>
//                   <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
//                     Total Amount (&#8377;)
//                   </td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
//                     {TotalAmount}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>

//           <div className="d-flex justify-content-center mb-5">
//             <span className=" mt-3 mr-3 h5" style={{ color: "#4A2A7D" }}>
//               This price will expire in :
//             </span>{" "}
//             <CountdownCircleTimer
//               // style={{ width: "50px", height: "50px" }} // Add height property
//               isPlaying
//               size={60}
//               strokeWidth={10}
//               duration={180}
//               colors={["#4A2A7D"]}
//               colorsTime={[10, 6, 3, 0]}
//               onComplete={() => ({ shouldRepeat: true, delay: 1 })}
//             >
//               {renderTime}
//             </CountdownCircleTimer>
//           </div>

//           <div className="col-md-12  " style={{ color: "#4A2A7D" }}>
//             <h6 className="text-center mt-1" style={{ color: "#4A2A7D" }}>
//               Bharat Batuk Pvt Ltd (BBPL)
//             </h6>
//             <p className="text-center mt-2">
//               Silver bought in last 7 days can not be sold.<br></br>BBPL offers
//               silver products through our partners.<br></br>Refer to Terms &
//               Conditions of Silver for details.
//             </p>
//           </div>

//           <div className="d-flex justify-content-center mt-4 mb-2 ">
//             <button
//               type="button"
//               // className="btn p-3 text-light"
//               onClick={() => goto()}
//               style={{
//                 borderRadius: "20px",
//                 fontSize: "20px",
//                 backgroundColor: "#4A2A7D",
//                 color: "white",
//                 padding: "5px 50px",
//               }}
//             >
//               Sell
//             </button>
//           </div>
//         </div>
//         <div className="col-md-2"></div>
//       </div>
//     </div>
//   );
// }
// export default SilverShell;

// import React, { useState } from "react";
// import { CountdownCircleTimer } from "react-countdown-circle-timer";
// import { Link, useLocation } from "react-router-dom";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { useCountdown } from "react-countdown-circle-timer";
// import axios from "axios";
// const Swal = require("sweetalert2");

// function SilverShell() {
//   useEffect(() => {
//     const login = sessionStorage.getItem("token");
//     if (!login) {
//       navigate("/");
//     }
//   }, []);

//   const [acc, setacc] = useState("");

//   // ----------------timer--------------
//   const {
//     path,
//     pathLength,
//     stroke,
//     strokeDashoffset,
//     remainingTime,
//     elapsedTime,
//     size,
//     strokeWidth,
//   } = useCountdown({ isPlaying: true, duration: 7, colors: "#abc" });

//   const renderTime = ({ remainingTime }) => {
//     const minutes = Math.floor(remainingTime / 60);
//     const seconds = remainingTime % 60;

//     return (
//       <div className="timer text-dark">
//         <div className="value" style={{ color: "#4A2A7D", fontSize: "14px" }}>
//           {minutes}:{seconds < 10 ? "0" : ""}
//           {seconds}
//         </div>
//       </div>
//     );
//   };
//   // -----------------------------------------------

//   const Token = sessionStorage.getItem("token");
//   // console.log("login token in inbox", Token);

//   const navigate = useNavigate();
//   const location = useLocation();
//   const [gold, SetGold] = useState("");
//   const data = location.state;
//   console.log("ordersummary data", data);

//   const Goldweight = data.data.data[0].totalQuantity;
//   const Goldrate = data.data.data[0].pricePerGram;
//   console.log("Goldrate", Goldrate);
//   // SetGold(Goldrate);
//   const GoldValue = data.data.data[0].amount;
//   const GST = data.data.data[0].gst;
//   const TotalAmount = data.data.data[0].amountAfterTax;
//   console.log("TotalAmount", TotalAmount);
//   const mtd = data.data.data[0].merchantTransactionId;
//   console.log("mtd", mtd);
//   const mt = data.data.data[0].metalType;
//   console.log("mt", mt);
//   const mClick = data.clickFirst;

//   console.log("mClick");

//   // -------------------------sell api -----------------------

//   // ----------------------------------
//   const callGetBuyAPI = async () => {
//     try {
//       const url = `${process.env.REACT_APP_BASE_URL}/augmont/sell-gold-silver`;

//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       // console.log("hiiiiiii");

//       const requestBody = {
//         // merchantTransactionId: mtd,
//         metalType: mt,
//         // quantity: "0.0002",
//         quantity: { Goldweight },
//       };

//       if (mClick === "A") {
//         requestBody["amount"] = TotalAmount;
//       } else {
//         requestBody["quantity"] = Goldweight;
//       }

//       const response = await axios.post(url, requestBody, { headers });

//       Swal.fire({
//         title: "Sold Successful",
//         html: `
//                 Transaction Number: ${response.data.data[0].result.data.transactionId}<br/><br/>
//                 Amount: ${TotalAmount}
//               `,
//         icon: "success",
//         confirmButtonColor: "#4A2A7D",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Ok",
//       }).then((d) => {
//         // navigate("/dashboard/goldSoldInvoice",{state:response});
//         navigate("/dashboard/portfolio");
//       });

//       var data = response.data;
//       // setShowSuccessPopup(true); // Show success popup after API call succeeds
//     } catch (error) {
//       // console.error("Error connecting to API", error);
//     }
//   };

//   useEffect(() => {
//     GetProfileAPI();
//   }, []);

//   // ------------------------get Profile for KYC ------------------------------------
//   const GetProfileAPI = async () => {
//     try {
//       const url = `${process.env.REACT_APP_BASE_URL}/getProfile`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       // const Profiledata = response.data;
//       // console.log("Get Profile API Response", response);
//       const accounttt = response.data.data[0].kyc.account_no;
//       setacc(accounttt);
//       // console.log("fghjk", accounttt);
//     } catch (error) {
//       // console.error("Error connecting to API", error);
//     }
//   };
//   // -----------------------end ------------------------------------------

//   const goto = () => {
//     // console.log("accountnoooooo", acc)
//     if (acc === null) {
//       navigate("/dashboard/silverbankdetails");
//     } else {
//       // callGetBuyAPI();

//       Swal.fire({
//         title: "Are you sure?",
//         text: "You won't be able to revert this!",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#880E4F",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Proceed to sell !",
//       })
//         .then((d) => {
//           if (d.isConfirmed === true) {
//             callGetBuyAPI();
//             navigate("/dashboard/history", {});
//           }
//         })
//         .catch((error) => {
//           // console.log("error", error);
//         });
//     }
//   };

//   return (
//     <div style={{ height: "auto" }}>
//       {/* <Link to="/dashboard/sellsilver" className="goBack  btn text-light " style={{ backgroundColor: "#4A2A7D" }}>
//                 <span>
//                     Back
//                 </span>
//             </Link> */}

//       <div className="row d-flex justify-content-between">
//         {/* <div className="col-md-2"></div> */}

//         <div
//           //  className="col-md-6  "
//           style={{
//             height: "auto",
//             borderRadius: "10px",
//             boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
//           }}
//         >
//           <h2
//             className="m-2 fw-bold text-center mb-3"
//             style={{ color: "#4A2A7D" }}
//           >
//             Order Summary
//           </h2>
//           {/* <div className="row d-flex justify-content-around" >

//                         <div className='col  d-flex m-2 p-1' style={{ border: "2px solid #880E4F", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(128, 0, 128, 0.5)" }}>
//                             <div className='col-md-2 ' >
//                                 <img src={Goldlogo} alt="here is logo" className='img-fluid' style={{ height: "80px" }} />
//                             </div>
//                             <div className='col-md-4   p-2 ml-5 ' style={{ height: "auto" }}>
//                                 <h2 className='text-center fw-bold' style={{ color: "#880E4F" }}>Augmont</h2>
//                                 <p className='text-center' style={{ color: "#880E4F" }}>Gold Weight: {Goldweight} gm</p>
//                             </div>
//                         </div>
//                     </div> */}

//           <div className="row">
//             <table class="table table-borderless">
//               <tbody style={{ color: "#880E4F" }}>
//                 <tr>
//                   <td></td>

//                   <td>Silver Weight AUGMONT (g)</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td className="mr-5">{Goldweight}</td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td>Silver Rate per gram (&#8377;)</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>{Goldrate}</td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td>Silver Value Of 1.0000/gm (&#8377;)</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>{GoldValue}</td>
//                 </tr>

//                 <tr>
//                   <td></td>
//                   <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
//                     Total Amount (&#8377;)
//                   </td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td className="fw-bold fs-5" style={{ color: "#880E4F" }}>
//                     {TotalAmount}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>

//           <div className="d-flex justify-content-center mb-5">
//             <span className=" mt-3 mr-3 h5" style={{ color: "#4A2A7D" }}>
//               This price will expire in :
//             </span>{" "}
//             <CountdownCircleTimer
//               // style={{ width: "50px", height: "50px" }} // Add height property
//               isPlaying
//               size={60}
//               strokeWidth={10}
//               duration={180}
//               colors={["#4A2A7D"]}
//               colorsTime={[10, 6, 3, 0]}
//               onComplete={() => ({ shouldRepeat: true, delay: 1 })}
//             >
//               {renderTime}
//             </CountdownCircleTimer>
//           </div>

//           <div className="col-md-12  " style={{ color: "#4A2A7D" }}>
//             <h6 className="text-center mt-1" style={{ color: "#4A2A7D" }}>
//               Bharat Batuk Pvt Ltd (BBPL)
//             </h6>
//             <p className="text-center mt-2">
//               Silver bought in last 7 days can not be sold.<br></br>BBPL offers
//               silver products through our partners.<br></br>Refer to Terms &
//               Conditions of Silver for details.
//             </p>
//           </div>

//           <div className="d-flex justify-content-center mt-4 mb-2 ">
//             <button
//               type="button"
//               // className="btn p-3 text-light"
//               onClick={() => goto()}
//               style={{
//                 borderRadius: "20px",
//                 fontSize: "20px",
//                 backgroundColor: "#4A2A7D",
//                 color: "white",
//                 padding: "5px 50px",
//               }}
//             >
//               Sell
//             </button>
//           </div>
//         </div>
//         <div className="col-md-2"></div>
//       </div>
//     </div>
//   );
// }
// export default SilverShell;
