import React, { useEffect, useState } from "react";
import axios from "axios"; // Import axios for making HTTP requests
// import "./coinbuy.css";
// import FormComp from "./BuyNow";
// import MainModal from "../../components/MainModal";
import { FaPlus } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
// import { config } from "../../config";
import { FaRegEdit } from "react-icons/fa";
// import { FaCheckCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Swal from "sweetalert2";
// import Confirmationpage from "./Confirmationpage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainModal from "../../components/MainModal";
import ShippingAddressForm from "../../components/ShippingAddressForm";
import ConfirmationPage from "../Goldpage/Confirmationpage";
import { Button } from "@mui/material";
import BackButton from "../../components/UI/BackButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getProfileApi } from "../../redux/features/profileSlice";
import { decryptFields } from "../../utils/kycStatus";
// import ShippingAddressForm from "../../components/ShippingAddressForm";
// import Swal from 'sweetalert2/dist/sweetalert2.js'

const ProductDetails = ({ onHide }) => {
  const { t } = useTranslation();
  const {
    deliveryAndCharges,
    buyBtn,
    productDetails,
    weight,
    metalPurity,
    skuId,
    desc,
  } = t("productDetailsPage");
  const navigate = useNavigate();
  const Token = sessionStorage.getItem("token");
  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const [cardView, setCardView] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [coinData, setCoinData] = useState(null);

  const location = useLocation();
  console.log("LOCATION^^^^^^^^^^^^^^^^^^^", location);
  const {
    productImages,
    purity,
    productWeight,
    sku,
    description,
    basePrice,
    metalType,
    name,
  } = location.state;
  const [mainImage, setMainImage] = useState(null);
  const [viewBuyNow, setViewBuyNow] = useState(false);
  const [productView, setproductView] = useState(true);
  // const [selectAddress, setSelectAddress] = useState(false);
  const [confirmationView, setConfirmationView] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [addressview, setaddressview] = useState(false);
  const [checkaddress, setcheckaddress] = useState([]);
  const [editdata, seteditdata] = useState({});
  const [checkitem, setcheckItem] = useState({});

  useEffect(() => {
    productDetailApi();
  }, []);

  const productDetailApi = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/products/show-product/${sku}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setCoinData(data?.data[0]?.result?.data);
      setMainImage(data?.data[0]?.result?.data?.productImages[0]?.url);

      // setSumdata(data?.data?.[0]?.result?.data?.filter(item => item.metalType === 'gold') || []);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  // Function to handle image click
  const handleImageClick = (index) => {
    // Change the main image URL to the clicked thumbnail's URL
    if (coinData) {
      const clickedImage = coinData.productImages[index].url;
      setMainImage(clickedImage);
    }
  };

  // ===========================KYC STATUS====================

  const [userDetails, setUserDetails] = useState({
    pan: false,
    bank: false,
    address: false,
  });

  useEffect(() => {
    // Get the stored userDetails from localStorage
    const storedDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

    // Decrypt the userDetails fields
    const decryptedDetails = decryptFields(storedDetails, [
      "pan",
      "bank",
      "address",
    ]);

    // Set the decrypted values in the state
    setUserDetails({
      pan: decryptedDetails.pan || false,
      bank: decryptedDetails.bank || false,
      address: decryptedDetails.address || false,
    });
  }, []);

  return (
    <>
      {productView && (
        <div>
          {/* <BackButton link="/dashboard/buygold/products" /> */}
          {coinData ? (
            <div>
              <div
                className="card p-4 "
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <div className="row">
                  <div
                    className="col-md-4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="row text-center">
                      <div
                        className="col-md-12"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="row">
                          <div className="c-l-md-12">
                            <img
                              className="img-fluid"
                              style={{ width: 170 }}
                              src={mainImage}
                              alt="img"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12">
                            <div className="d-flex justify-content-evenly align-items-center flex-wrap">
                              {coinData.productImages.map((image, index) => (
                                <div
                                  key={index}
                                  // className="img-thumbnail mt-1 contentshadow"
                                  style={{
                                    width: "50px",
                                    // backgroundColor: "red",
                                    borderRadius: "5px",
                                    border: "2px solid gold",
                                    padding: "0.3rem",
                                  }}
                                >
                                  <img
                                    // style={{ height: 60 }}
                                    width="100%"
                                    src={image.url}
                                    alt={coinData.title}
                                    onClick={() => handleImageClick(index)}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-12">
                            <div>
                              <h5 className="mt-4" style={{ color: "#4A2A7D" }}>
                                {name}
                              </h5>
                              <p className="mt-2" style={{ color: "#4A2A7D" }}>
                                {deliveryAndCharges}
                              </p>
                              <p style={{ color: "#4A2A7D" }}>
                                &#8377;{basePrice} (All Incl.)
                              </p>
                            </div>

                            {userDetails.pan && userDetails.address ? (
                              <Link
                                to="/dashboard/shippingAddress"
                                state={(location.state, coinData)}
                                // state={coinData}
                              >
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#4a2a7d",
                                    color: "white",
                                    "&:hover": {
                                      backgroundColor: "#4a2a7d",
                                    },
                                  }}
                                >
                                  Buy Now
                                </Button>
                              </Link>
                            ) : (
                              <Link to="/dashboard/ProfileSetting">
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#4a2a7d",
                                    color: "white",
                                    "&:hover": {
                                      backgroundColor: "#4a2a7d",
                                    },
                                  }}
                                >
                                  Complete KYC
                                </Button>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div>
                      <div>
                        <div
                          className="contentshadow"
                          style={{
                            border: "2px solid gold",
                            borderRadius: "5px",
                          }}
                        >
                          <h4 style={{ color: "#4A2A7D", textAlign: "center" }}>
                            {productDetails}
                          </h4>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  color: "#4A2A7D",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h4>{weight}</h4>
                                <h4 style={{ color: "#FFB901" }}>
                                  {productWeight} gm
                                </h4>
                              </div>
                              <div
                                style={{
                                  color: "#4A2A7D",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h4>{metalPurity}</h4>
                                <h4 style={{ color: "#FFB901" }}> {purity}</h4>
                              </div>
                              <div
                                style={{
                                  color: "#4A2A7D",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <h4>{skuId}</h4>
                                <h4 style={{ color: "#FFB901" }}>{sku}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="contentshadow"
                          style={{
                            border: "2px solid gold",
                            borderRadius: "5px",
                          }}
                        >
                          <h4
                            className="colore"
                            style={{ color: "#4A2A7D", textAlign: "center" }}
                          >
                            {desc}
                          </h4>
                          <p
                            className="coloreddescriptio"
                            style={{ color: "#4A2A7D", textAlign: "center" }}
                          >
                            {description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default ProductDetails;
