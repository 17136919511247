import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./tiles.scss";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Navbar from "./components/Navbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getProfileApi } from "./redux/features/profileSlice";
import { decryptFields, encryptFields } from "./utils/kycStatus";

// Initialize AOS library
AOS.init();

// import Navbar from '../TopNavbar/navbar';

function Tiless() {
  const { t } = useTranslation();
  const {
    goldTitle,
    silverTitle,
    portfolioTitle,
    historyTitle,
    profileTitle,
    gold,
    silver,
    portfolio,
    history,
    profile,
  } = t("home");

  const homeLinks = [
    {
      name: "home",
      img: "/goldHome.png",
      title: goldTitle,
      link: "/dashboard/buygold",
      contentKey:
        "Experience the simplicity of owning gold digitally. Securely buy, store, and manage gold at your convenience, without the hassle of physical storage.",
    },
    {
      name: "home",
      img: "/silverHome.png",
      title: silverTitle,
      link: "/dashboard/buysilver",
      contentKey:
        "Unlock the potential of silver with a seamless digital platform. Purchase, store, and track silver securely and effortlessly, all from your device.",
    },
    {
      name: "spotGold",
      img: "/jewellery_main.png",
      title: "Jewellery",
      // link: "/dashboard/spotGold",
      link: "/shop",
      contentKey:
        "Explore our beautiful collection of machine-made gold jewellery, crafted with precision for every special moment. Get modern designs with the trust and purity of gold.",
    },
    // {
    //   name: "spotGold",
    //   img: "./goldFDLogo.png",
    //   title: "Gold FD",
    //   link: "/dashboard/goldFD",
    //   contentKey: gold,
    // },
    {
      name: "home",
      img: "/portfolioHome.png",
      title: portfolioTitle,
      link: "/dashboard/portfolio",
      contentKey:
        "Manage all your gold and silver assets in one place. Your portfolio lets you track and grow your wealth easily and transparently.",
    },
    {
      name: "home",
      img: "/gold_sip.png",
      title: "Gold SIP",
      link: "/dashboard/gold-sip",
      contentKey:
        "Contribute a fixed amount regularly to accumulate gold over time. Enjoy a hassle-free way to build your gold savings secure, simple, and accessible!.",
    },
    {
      name: "home",
      img: "/silver_sip.png",
      title: "Silver SIP",
      link: "/dashboard/silver-sip",
      contentKey:
        "Set aside a fixed amount at regular intervals and watch your silver grow. It’s an easy and secure way to build your silver savings perfect for every budget!.",
    },
    {
      name: "home",
      img: "/profileHome.png",
      title: profileTitle,
      link: "/dashboard/profile",
      contentKey: profile,
    },
    {
      name: "home",
      img: "/historyHome.png",
      img2: "/history2.png",
      title: historyTitle,
      link: "/dashboard/history",
      contentKey: history,
    },
  ];

  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileApi());
  }, []);

  const profileSliceResponse = useSelector(
    (state) => state?.profileSlice?.data?.data
  );

  useEffect(() => {
    if (profileSliceResponse) {
      const { pan_no, account_no } = profileSliceResponse?.[0]?.kyc || {};
      const { _id } = profileSliceResponse?.[0]?.billAddressUser || {};

      let userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};

      // Decrypt pan_no and account_no if they are encrypted
      userDetails = decryptFields(userDetails, ["pan", "bank"]);

      // Check if pan_no and account_no are valid, then update the corresponding userDetails fields
      if (pan_no && account_no) {
        userDetails.pan = true;
        userDetails.bank = true;
      }

      if (_id) {
        userDetails.address = true;
      }

      // Encrypt the updated userDetails object before saving to localStorage
      userDetails = encryptFields(userDetails, ["pan", "bank", "address"]);

      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    }
  }, [profileSliceResponse]);

  return (
    <div style={{ backgroundColor: "#DCDAFF", minHeight: "100vh" }}>
      <div
        className="Tiles"
        style={{
          width: "90%",
          margin: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: "40px 0",
        }}
      >
        <div>
          <div
            className=" mb-4"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              width: "100%",
            }}
          >
            {homeLinks.map((item, i) => (
              <div
                key={i}
                className="home-card"
                onMouseEnter={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Link to={item.link} style={{ width: "100%" }}>
                  <div className="tiles_card" style={{ width: "100%" }}>
                    <div
                      // className="card-body d-flex flex-row"
                      style={{
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        src={
                          hoveredIndex === i && item.img2 ? item.img2 : item.img
                        }
                        alt={t(item.title)}
                        width="20%"
                      />
                      <div
                        className="content"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <h4
                          className="title"
                          style={{ color: "#4A2A7D", fontSize: "1.5rem" }}
                        >
                          {t(item.title)}
                        </h4>
                        <p
                          className="desc"
                          style={{ color: "#4A2A7D", fontSize: "0.9rem" }}
                        >
                          {item.contentKey}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <Footer /> */}
      {/* <div className='container-fluid mt-2' style={{ backgroundColor: "rgb(243 241 245)", boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)", position: "absolute", bottom: 0 }}>
                <div className='row  text-center p-3 text-dark '>
                    <h5>All rights are reserved by @Bharat Batuk Pvt. Ltd.</h5>
                </div>
            </div> */}
    </div>
  );
}

export default Tiless;
