import "./App.css";
import "./pages/Profile/tab.scss";
import {
  Routes,
  Route,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { useEffect, useState } from "react";
import "sweetalert2/src/sweetalert2.scss";
import Navbar from "./components/Navbar";
import Footer from "./components/footer/Footer";
import { generateToken, messaging } from "./notifications/firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";

function App() {
  let userDetails;
  try {
    userDetails = JSON.parse(localStorage.getItem("userDetails"));
  } catch (error) {
    console.error("Error parsing userDetails:", error);
  }
  const userId = userDetails?._id;

  // const socket = io(process.env.REACT_APP_BASE_URL);

  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const shouldHideNavbar = location.pathname.includes("dashboard");

  const shouldHideFooter =
    location.pathname.includes("dashboard") ||
    location.pathname.includes("account") ||
    location.pathname.includes("login");

  const shopPath = location.pathname.includes("shop");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      console.log(payload);
      toast.success(payload.notification.body);
    });
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {})
        .catch((err) => {
          console.error("Service Worker registration failed:", err);
        });
    }
  }, []);

  // useEffect(() => {
  //   socket.emit("user_online", userId);

  //   // Handle tab close
  //   const handleBeforeUnload = () => {
  //     socket.emit("user_logged_out", userId);
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup on unmount
  //   return () => {
  //     // Emit 'user_offline' just before disconnecting
  //     socket.emit("user_status_offline", userId, () => {
  //       socket.disconnect();
  //     });
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [userDetails]);

  return (
    <div
      className={`app_layout ${
        shouldHideNavbar ? "hide-navbar" : "home_app_layout"
      }`}
    >
      <div className={shopPath ? "shop_style" : ""}>
        {!shouldHideNavbar && <Navbar />}
      </div>
      <div>
        <Outlet />
      </div>
      <div>{!shouldHideFooter && <Footer />}</div>
    </div>
  );
}

export default App;
