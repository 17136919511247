import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import Swal from "sweetalert2";

export const goldAndSliverBuyApi = createAsyncThunk(
  "goldAndSilverBuy",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/augmont/buy`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialState = {
  data: [],
  errors: [],
  status: "idle",
};

const goldAndSilverBuySlice = createSlice({
  name: "goldAndSilverBuy",
  initialState,
  reducers: {
    resetState: (state) => {
      return initialState; // Reset state to initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(goldAndSliverBuyApi.pending, (state) => {
        state.status = "loading";
        state.errors = [];
      })
      .addCase(goldAndSliverBuyApi.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(goldAndSliverBuyApi.rejected, (state, action) => {
        state.status = "idle";
        const errorStatus = action.payload?.status;

        // Show SweetAlert on 404 or other API failure
        if (errorStatus === 404) {
          Swal.fire({
            icon: "error",
            title: "Not Found",
            text: "The requested resource could not be found.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "API Error",
            text: "An error occurred while processing your request.",
          });
        }

        // Optionally add the error to the state
        state.errors.push(action.payload);
      });
  },
});

export const { resetState } = goldAndSilverBuySlice.actions;

export default goldAndSilverBuySlice.reducer;
