import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n.js";
import PageNotFound from "./pages/pageNotFound/PageNotFound.jsx";
import Shop from "./pages/shop/Shop.jsx";
import DashboardLayout from "./pages/dashboardLayout/DashboardLayout.jsx";
import Tiless from "./Tiles.js";
import Login from "./login.js";
import Checkout from "./pages/checkout/Checkout.jsx";
import ProductDetail from "./pages/productDetail/ProductDetail.jsx";
import Cart from "./pages/cart/Cart.jsx";
import Goldify from "./pages/Goldpage/Goldify.js";
import Dashboard from "./pages/Dashboard/Dashboard.jsx";
import Silvify from "./pages/Silvify/Silvify.js";
import Products from "./pages/products/Products.jsx";
import SpotGold from "./pages/spotGold/SpotGold.jsx";
import SpotGoldProductDetails from "./pages/spotGold/SpotGoldProductDetails.jsx";
import Address from "./pages/address/Address.jsx";
import Confirmation from "./pages/Goldpage/Confirmation.jsx";
import GoldFD from "./pages/goldFD/GoldFD.jsx";
import GoldFDDetails from "./pages/goldFD/GoldFDDetails.jsx";
import HistoryPortfolio from "./pages/history.js";
import HistoryInvoice from "./pages/historyInvoice.js";
import Portfolio from "./pages/Portfolio.js";
import Profile from "./pages/Profile/Profile.js";
import ProfileSetting from "./pages/Profile/ProfileSetting.js";
import BuySell from "./components/BuySell.jsx";
import ProductDetails from "./pages/productDetails/ProductDetails.jsx";
import ShippingAddress from "./pages/ShippingAddress/ShippingAddress.jsx";
import CategoryProducts from "./pages/categoryProducts/CategoryProducts.jsx";
import OrderSummary from "./pages/Goldpage/orderSummary.js";
import SilverOrderSummary from "./pages/Silvify/orderSummary.js";
import Billing from "./pages/Goldpage/Billing-form.js";
import Invoice from "./pages/Goldpage/invoice.js";
import GoldShell from "./pages/Goldpage/goldsellsummary.js";
import SilverInvoice from "./pages/Silvify/invoice.js";
import GoldSoldInvoice from "./pages/Goldpage/GoldSoldInvoice.jsx";
import SilverSoldInvoice from "./pages/Silvify/GoldSoldInvoice.jsx";
import DeliveryGold from "./pages/Goldpage/DeliveryGold.js";
import DeliverySilver from "./pages/Silvify/DeliveryGold.js";
import SilverShell from "./pages/Silvify/goldsellsummary.js";
import GoldFDLayout from "./pages/goldFD/GoldFDLayout.jsx";
import AllProducts from "./pages/allProducts/AllProducts.jsx";
import Home from "./pages/home/Home.jsx";
import ContactUs from "./pages/contactUs/ContactUs.jsx";
import Faq from "./pages/faq/Faq.jsx";
import CustomGoldAndSilver from "./pages/customGoldAndSilver/CustomGoldAndSilver.jsx";
import ShopLayout from "./pages/shop/ShopLayout.jsx";
import AboutUs from "./pages/aboutUs/AboutUs.jsx";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy.jsx";
import Terms from "./pages/terms/Terms.jsx";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShippingPolicy from "./pages/shippingPolicy/ShippingPolicy.jsx";
import RefundPolicy from "./pages/refundPolicy/RefundPolicy.jsx";

import "react-multi-carousel/lib/styles.css";
import Blogs from "./pages/blogs/Blogs.jsx";
import ViewBlog from "./pages/blogs/ViewBlog.js";

import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import Loader from "./components/Loader.jsx";
import BackdropLoader from "./components/BackdropLoader.jsx";
import CustomGoldAndSilverSip from "./pages/customGoldAndSilverSip/CustomGoldAndSilverSip.jsx";
import SipHistory from "./pages/sipHistory/SipHistory.jsx";
import SipHistoryDetails from "./pages/sipHistoryDetails/SipHistoryDetails.jsx";
import HelpSupport from "./pages/helpSupport/HelpSupport.jsx";
import BuySummary from "./pages/buySummary/BuySummary.jsx";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "login",
        element: <Login />,
      },
      //  ========================SHOP===========================
      {
        path: "shop",
        element: <ShopLayout />,
        children: [
          {
            index: true,
            element: <Shop />,
          },
          {
            path: "all-products",
            element: <AllProducts />,
          },
          {
            path: "cart",
            element: <Cart />,
          },
          {
            path: ":id",
            element: <ProductDetail />,
          },
          {
            path: "allProducts",
            element: <AllProducts />,
          },
          {
            path: "category/:categoryId",
            element: <CategoryProducts />,
          },
        ],
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        path: "blogs",
        element: <Blogs />,
      },
      {
        path: "blog/:title_url",
        element: <ViewBlog />,
      },
      {
        path: "faqs",
        element: <Faq />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms-conditions",
        element: <Terms />,
      },
      {
        path: "digital-gold",
        element: <CustomGoldAndSilver />,
      },
      {
        path: "digital-silver",
        element: <CustomGoldAndSilver />,
      },
      // {
      //   path: "category/:categoryId",
      //   element: <CategoryProducts />,
      // },
      // {
      //   path: "allProducts",
      //   element: <AllProducts />,
      // },
      // {
      //   path: "cart",
      //   element: <Cart />,
      // },
      // {
      //   path: ":id",
      //   element: <ProductDetail />,
      // },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "account",
        element: <Tiless />,
      },
      {
        path: "shipping-policy",
        element: <ShippingPolicy />,
      },
      {
        path: "refund-policy",
        element: <RefundPolicy />,
      },
      {
        path: "dashboard",
        element: <Dashboard />, // Layout component for dashboard routes
        children: [
          {
            index: true,
            element: <Tiless />,
          },
          {
            path: "buygold",
            element: <Goldify />,
          },
          {
            path: "products",
            element: <Products />,
          },
          {
            path: "productDetails",
            element: <ProductDetails />,
          },
          {
            path: "shippingAddress",
            element: <ShippingAddress />,
          },
          {
            path: "confirmation",
            element: <Confirmation />,
          },
          {
            // path: "orderSummary",
            // element: <OrderSummary />,
            path: "gold-buy-Summary",
            element: <BuySummary />,
          },
          // {
          //   path: "billing",
          //   element: <Billing />,
          // },
          {
            path: "invoice",
            element: <Invoice />,
          },
          {
            path: "silverinvoice",
            element: <SilverInvoice />,
          },
          {
            path: "goldsellsummary",
            element: <GoldShell />,
          },
          {
            path: "silversellsummary",
            element: <SilverShell />,
          },
          {
            path: "goldSoldInvoice",
            element: <GoldSoldInvoice />,
          },
          {
            path: "silverSoldInvoice",
            element: <SilverSoldInvoice />,
          },
          {
            path: "deliverygold",
            element: <DeliveryGold />,
          },
          {
            path: "deliverySilver",
            element: <DeliverySilver />,
          },
          // {
          //   path: "silverbilling",
          //   element: <SilverBilling />,
          // },

          // ==================== SILVER ROUTES==============================
          {
            path: "buysilver",
            element: <Silvify />,
            children: [
              {
                path: "products",
                element: <Products />,
              },
            ],
          },
          {
            // path: "silverorderSummary",
            // element: <SilverOrderSummary />,
            path: "silver-buy-Summary",
            element: <BuySummary />,
          },
          {
            path: "spotGold",
            element: <SpotGold />,
            children: [
              {
                path: ":id",
                element: <SpotGoldProductDetails />,
                children: [
                  {
                    path: "address",
                    element: <Address />,
                    children: [
                      {
                        path: "confirmation",
                        element: <Confirmation />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // ========================== GOLD FD=============================
          {
            path: "goldFD",
            element: <GoldFDLayout />,
            children: [
              {
                index: true,
                element: <GoldFD />,
              },
              {
                path: "goldFD-details",
                element: <GoldFDDetails />,
              },
            ],
          },
          // ========================== HISTORY ROUTES=============================
          {
            path: "history",
            element: <HistoryPortfolio />,
          },
          {
            path: "historyinvoice",
            element: <HistoryInvoice />,
          },
          // ========================== PORTFOLIO ROUTES=============================
          {
            path: "portfolio",
            element: <Portfolio />,
          },
          // ========================== PROFILE ROUTES=============================
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "ProfileSetting",
            element: <ProfileSetting />,
          },
          // ========================== GOLD SIP ROUTES=============================
          {
            path: "gold-sip",
            element: <CustomGoldAndSilverSip />,
          },

          // ========================== SILVER SIP ROUTES=============================
          {
            path: "silver-sip",
            element: <CustomGoldAndSilverSip />,
          },
          {
            path: "sip-history",
            element: <SipHistory />,
          },
          {
            path: ":sipId",
            element: <SipHistoryDetails />,
          },
          {
            path: "helpSupport",
            element: <HelpSupport />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
// const queryClient = new QueryClient();

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<BackdropLoader />}>
      <Provider store={store}>
        {/* <QueryClientProvider client={queryClient}> */}
        <RouterProvider router={router} />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Slide}
        />
        {/* </QueryClientProvider> */}
      </Provider>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
