import React, { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import axios from "axios";
import "./main-blogs.css";
import Breadcrumb from "../../components/Breadcrumb";
import { IoIosSearch } from "react-icons/io";
// import Navbar1 from "../../../components/Navbar";
// import Footer from "../../../landing_page/Footer";
// import teamWork from "../../../assets/landing-page/get-started.png";
// import "../../../landing_page/offers.css";
// import offerIcon from "../../../assets/landing-page/briefcase.png";
// import { baseUrl } from "../../../utils/baseUrl";

const Blogs = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    // Fetch blog posts
    axios
      .get(`${baseUrl}/admin_panel/blog_PostList`)
      .then((response) => {
        setBlogPosts(response.data.data);
        setFilteredData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });

    axios
      .get(`${baseUrl}/admin_panel/category_List`)
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterAndSearch(e.target.value, filter);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    filterAndSearch(searchTerm, e.target.value);
  };

  const filterAndSearch = (term, filter) => {
    let filteredResults = blogPosts;

    if (filter) {
      filteredResults = filteredResults.filter(
        (item) => item.categoryId === filter
      );
    }

    if (term) {
      filteredResults = filteredResults.filter((item) =>
        item.title.toLowerCase().includes(term.toLowerCase())
      );
    }

    setFilteredData(filteredResults);
  };

  const handleShare = () => {
    alert("Share this blog post!");
  };

  return (
    <>
      <div className="offerings-container">
        {/* <Breadcrumb currentPageName="Blogs"/> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
            padding: " 9px",
          }}
        >
          {/* ############################################*/}

          <div
            // className="row"
            className="title_And_Searchbar"
            // style={{
            //   width: "100%",
            //   display: "flex",
            //   justifyContent: "space-between",
            //   color: "#4a2a7d",
            //   alignItems: "center",
            //   textAlign: "center",
            // }}
          >
            <div
            // className="col-6"
            // style={{
            //   display: "flex",
            //   width: "58%",
            //   justifyContent: "start",
            //   color: "#4a2a7d",
            // }}
            >
              <h2 className="offerings-title">Our Blogs</h2>
            </div>
            {/*****************SEARCH*******************/}
            <div
            // className="col-6"
            // style={{ display: "flex", width: "42%", justifyContent: "end" }}
            >
              <div
                style={{
                  display: "flex",
                  border: "1px solid",
                  borderRadius: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <IoIosSearch size={30} />
                <input
                  type="text"
                  placeholder="Search Blog"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{
                    // flex: "1",
                    // width: "13rem",
                    padding: "0.5rem",
                    border: "none",
                    outline: "none",
                  }}
                />
                <select
                  value={filter}
                  onChange={handleFilterChange}
                  style={{
                    padding: "12px",
                    borderRadius: "5px",
                    // border: "1px solid #ccc",
                    border: "none",
                    // width: "5rem",
                    // paddingLeft: "34px",
                  }}
                >
                  <option value="" style={{ color: "black" }}>
                    All
                  </option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat._id}>
                      {cat.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* <div className="row" style={{ width: "100%" }}>
            <div
              className="col-6"
              style={{
                display: "flex",
                width: "58%",
                justifyContent: "start",
                color: "#4a2a7d",
              }}
            >
              <h2 className="offerings-title">Our Blogs</h2>
            </div>
            <div
              className="col-6"
              style={{ display: "flex", width: "42%", justifyContent: "end" }}
            >
              <div
                style={{
                  display: "flex",
                  border: "1px solid",
                  borderRadius: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <IoIosSearch size={30} />
                <input
                  type="text"
                  placeholder="Search Blog"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{
                    flex: "1",
                    width: "13rem",
                    padding: "0.5rem",
                    border: "none",
                    outline: "none",
                  }}
                />
                <select
                  value={filter}
                  onChange={handleFilterChange}
                  style={{
                    padding: "12px",
                    borderRadius: "5px",
                    // border: "1px solid #ccc",
                    border: "none",
                    width: "5rem",
                    // paddingLeft: "34px",
                  }}
                >
                  <option value="" style={{ color: "black" }}>
                    All
                  </option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat._id}>
                      {cat.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3rem",
            marginBottom: "3rem",
          }}
        >
          <div className={filteredData?.length > 0 ? "blog-grid" : ""}>
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((post, index) => (
                <BlogCard
                  key={index}
                  blogId={post._id}
                  title_url={post.title_url}
                  author={"Batuk"}
                  title={post.title}
                  description={post.description}
                  image={post.image}
                  likes={post.likes}
                  createdAt={post.createdAt}
                  onShare={handleShare}
                />
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <img src="/no_data.png" alt="no data" />
                <h1 style={{ color: "#4a2a7d" }}>No blogs available</h1>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Blogs;
