import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n.use(LanguageDetector).use(initReactI18next).use(Backend).init({
  debug: false,
  fallbackLng: "en",
  returnObjects: true,
  // resources: {
  //   en: {
  //     translation: {
  //       greeting: "Hello, How are you?",
  //     },
  //   },
  //   // hi:{
  //   //   translation:{
  //   //     greeting:"नमस्ते, आप कैसे हैं?",
  //   //   },
  //   // },
  //   ta: {
  //     translation: {
  //       greeting: "வணக்கம் எப்படி இருக்கிறாய்?",
  //     },
  //   },
  // },
});
