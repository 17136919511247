// UserDashBoard.jsx
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import io from "socket.io-client";
// import chatIcon from "../assets/icons/Chat box.png";
import "./HelpSupport.css";
import { formatMessageTime } from "../../utils/timeChat";
import { Button, TextField } from "@mui/material";
import socket from "../../utils/socket";
import { toast } from "react-toastify";

const HelpSupport = () => {
  const userId = JSON.parse(localStorage.getItem("userDetails"))?._id;

  // Configuration
  const baseUrl = process.env.REACT_APP_BASE_URL; // Your API base URL
  // const socket = io(baseUrl); // Your Socket.IO server URL

  const Token = sessionStorage.getItem("token");

  // const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // const userId = userDetails?._id || null;

  // State Variables
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [loadingTicketDetails, setLoadingTicketDetails] = useState(false);
  const [error, setError] = useState(null);
  const [senderId, setSenderId] = useState("");

  const messagesEndRef = useRef(null);
  const socketRef = useRef(null); // Ref to hold the socket instance

  useEffect(() => {
    getTicketData();
  }, []);

  // Fetch all tickets data
  const getTicketData = async () => {
    setLoadingTickets(true);
    setError(null);
    try {
      const url = `${baseUrl}/admin_panel/getUserAllTickets`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      console.log("Tickets fetched successfully:", response);
      if (response.data && response.data.data) {
        setTickets(response.data.data);
        setFilteredTickets(response.data.data);
      } else {
        setTickets([]);
        setFilteredTickets([]);
        setError("No tickets data available.");
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      setError("Failed to fetch tickets.");
    } finally {
      setLoadingTickets(false);
    }
  };

  // Filter tickets based on search query with debouncing
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchQuery.trim() === "") {
        setFilteredTickets(tickets);
      } else {
        const lowercasedQuery = searchQuery.toLowerCase();
        const filtered = tickets.filter((ticket) =>
          ticket.ticket_token.toLowerCase().includes(lowercasedQuery)
        );
        setFilteredTickets(filtered);
      }
    }, 300); // 300ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery, tickets]);

  const fetchSendMessage = async (ticket) => {
    if (!ticket) {
      setError("Invalid ticket ID.");
      return;
    }

    setSelectedTicket(ticket);
    setSenderId(ticket.customerRefNo);
    socket.emit("join_ticket", ticket._id);
    setLoadingTicketDetails(true);
    setError(null);
    try {
      const url = `${baseUrl}/admin_panel/getSendMessage/${ticket._id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      console.log("Single ticket fetched successfully:", response);
      setMessages(response.data.data);
    } catch (error) {
      console.error("Error fetching single ticket:", error);
      setError("Failed to fetch ticket details.");
    } finally {
      setLoadingTicketDetails(false);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Handle sending a new message
  const handleSendMessage = () => {
    if (newMessage.trim() === "" || !selectedTicket) return;

    const message = {
      ticketId: selectedTicket._id,
      senderId: senderId,
      message: newMessage,
      createdAt: new Date().toISOString(), // Add timestamp
      type: "sent", // Set message type
    };

    socket.emit("new_message", message);
    setNewMessage("");
  };

  // Handle pressing Enter to send a message
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASE_URL);

    if (selectedTicket) {
      socket.emit("join_ticket", selectedTicket._id);
    }

    socket.on("receive_message", (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    return () => {
      socket.disconnect();
    };
  }, [selectedTicket]);

  // ====================================

  const [ticket, setTicket] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    setTicket(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!ticket) {
      setErrorMessage("Ticket content cannot be empty.");
      return;
    }

    try {
      const url = `${baseUrl}/admin_panel/createTicket`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.post(
        url,
        {
          description: ticket,
        },
        { headers }
      );
      getTicketData();
      toast.success("Ticket created successfully!");
      setSuccessMessage("Ticket created successfully!");
      setTicket(""); // Clear the input field
      setErrorMessage(""); // Clear any error message
    } catch (error) {
      setErrorMessage("Error creating the ticket. Please try again.");
    }
  };

  useEffect(() => {
    // toast.info("outside");
    const handleBeforeUnload = () => {
      // Emit the user_logged_out event only when the tab is closing
      socket.emit("user_logged_out", userId);
    };

    const handleUnload = () => {
      // Emit the user_status_offline event and disconnect the socket when the browser or tab is closing
      socket.emit("user_status_offline", userId);
      socket.disconnect();
    };

    // Attach the event listeners once when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    // Return an empty cleanup function so it does not run on any dependency change
    return () => {
      // toast.info("inside");
      // Remove event listeners when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  useEffect(() => {
    if (userId) socket.emit("user_online", userId);
  }, []);

  return (
    <>
      <div className="ticket-form">
        <form onSubmit={handleSubmit} style={{ display: "flex", gap: "1rem" }}>
          <TextField
            variant="outlined"
            label="Ticket"
            size="small"
            value={ticket}
            onChange={handleInputChange}
          />

          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#4a2a7d",
              color: "white",
              "&:hover": {
                backgroundColor: "#4a2a7d",
              },
            }}
          >
            Submit
          </Button>
        </form>

        {/* Display success or error message */}
        {/* {successMessage && <i style={{ color: "red" }}>{successMessage}</i>} */}
        {errorMessage && <i style={{ color: "red" }}>{errorMessage}</i>}
      </div>

      {/* ==========================================  */}

      <div>
        <div className="content">
          <div className="content__body" style={{ marginTop: "2rem" }}>
            <div className="tickets">
              <div className="tickets__header">
                <div>
                  <span style={{ color: "#4a2a7d" }}>
                    CHAT ({tickets.length})
                  </span>
                </div>
                {/* Search Input Field */}
                <div className="tickets__search">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by Ticket Token..."
                    style={{
                      padding: "6px 10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      width: "130px",
                    }}
                  />
                </div>
              </div>
              <div className="tickets__body">
                {loadingTickets ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ padding: "10px" }}>Loading tickets...</p>
                  </div>
                ) : filteredTickets.length > 0 ? (
                  filteredTickets.map((ticket) => (
                    <div
                      className={`ticket ${
                        selectedTicket?.ticket_token === ticket.ticket_token
                          ? "active"
                          : ""
                      }`}
                      key={ticket._id}
                      onClick={() => fetchSendMessage(ticket)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        borderBottom: "1px solid #eee",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          selectedTicket?.ticket_token === ticket.ticket_token
                            ? "#f0f0f0"
                            : "transparent",
                      }}
                    >
                      <div
                        className="ticket__avatar"
                        style={{ marginRight: "10px" }}
                      >
                        <img
                          src="https://doodleipsum.com/38x38/avatar-3?bg=ffffff&i=7babc38a84fa9f3d32575d2f9ec92bce"
                          alt="Avatar"
                          style={{
                            borderRadius: "50%",
                            width: "38px",
                            height: "38px",
                          }}
                        />
                      </div>
                      <div className="ticket__info" style={{ flex: 1 }}>
                        <div
                          className="ticket__title"
                          style={{ fontWeight: "bold" }}
                        >
                          {ticket.description}
                        </div>
                        <div className="ticket__from" style={{ color: "#555" }}>
                          {ticket.ticket_token}
                        </div>
                      </div>
                      <div className="ticket__label">
                        {ticket.ticket_status == "closed" ? (
                          <label className="offline" style={{ color: "red" }}>
                            Closed
                          </label>
                        ) : (
                          <label className="online" style={{ color: "green" }}>
                            Open
                          </label>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ padding: "10px" }}>No tickets found.</p>
                )}
              </div>
            </div>

            <div className="dialog">
              <div className="dialog__header">
                <div className="user">
                  <img
                    src="https://doodleipsum.com/38x38/avatar-3?bg=ffffff&i=7babc38a84fa9f3d32575d2f9ec92bce"
                    alt="User Avatar"
                  />
                  <span style={{ fontSize: "15px", color: "#4a2a7d" }}>
                    {selectedTicket?.customerRefNo?.name || "Select a ticket"}
                  </span>
                </div>
              </div>

              {selectedTicket && !loadingTicketDetails ? (
                <div
                  className="dialog__body"
                  style={{
                    position: "relative",
                    paddingBottom: "5px",
                    height: "97%",
                  }}
                >
                  <div
                    style={{
                      overflowY: "auto",
                      position: "relative",
                      paddingBottom: "60px",
                      height: "100%",
                    }}
                  >
                    {messages.length > 0 ? (
                      messages.map((message, index) => (
                        <div
                          key={index}
                          className={`message ${
                            message.senderId === "Batuk" ? "sent" : "received"
                          }`}
                          style={{
                            display: "flex",
                            justifyContent:
                              message.senderId === "Batuk"
                                ? "flex-end"
                                : "flex-start",
                            padding: "0",
                            width: "fit-content",
                          }}
                        >
                          <div
                            style={{
                              maxWidth: "300px",
                              padding: "10px 10px 0 10px",
                              borderRadius: "10px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "normal",
                              backgroundColor:
                                message.senderId === "Batuk"
                                  ? "rgb(240, 240, 240)"
                                  : "#4d16a4",
                              color:
                                message.senderId === "Batuk"
                                  ? "black"
                                  : "white",
                            }}
                          >
                            <p style={{ margin: 0 }}>{message.message}</p>
                            <p
                              style={{
                                display: "flex",
                                justifyContent:
                                  message.senderId === "Batuk"
                                    ? "flex-end"
                                    : "flex-end",
                                padding: "0",
                                fontSize: "x-small",
                              }}
                            >
                              {formatMessageTime(
                                message.createdAt
                              ).toUpperCase()}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div
                        className="dialog__body"
                        style={{
                          position: "relative",
                          paddingBottom: "5px",
                          height: "97%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div className="chat-box-blank">
                            <div className="chat-icon-center">
                              <img
                                className="chat-icon"
                                // src={chatIcon}
                                src="/Chat box.png"
                                alt="chat icon"
                                width={100}
                              />
                            </div>
                            <p className="ticket-text">
                              No message in the chat
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div ref={messagesEndRef} />
                  </div>

                  {selectedTicket?.ticket_status === "open" && (
                    <div
                      className="chat-input"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        display: "flex",
                        padding: "10px",
                        borderTop: "1px solid #ccc",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Type your message..."
                        style={{
                          flex: 1,
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                        disabled={!selectedTicket}
                      />
                      <button
                        onClick={handleSendMessage}
                        style={{
                          padding: "8px 16px",
                          marginLeft: "8px",
                          border: "none",
                          borderRadius: "4px",
                          backgroundColor: "#4a2a7d",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        disabled={!selectedTicket}
                      >
                        Send
                      </button>
                    </div>
                  )}

                  {selectedTicket?.ticket_status === "closed" && (
                    <div
                      className="chat-input"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        display: "flex",
                        padding: "10px",
                        borderTop: "1px solid #ccc",
                        backgroundColor: "#f9f9f9",
                        textAlign: "center",
                      }}
                    >
                      <p
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Type your message..."
                        style={{
                          flex: 1,
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          backgroundColor: "#ec4a4a ",
                          color: "#fff",
                        }}
                        disabled={!selectedTicket}
                      >
                        Ticket closed
                      </p>
                    </div>
                  )}
                </div>
              ) : loadingTicketDetails ? (
                <p>Loading ticket details...</p>
              ) : (
                <div
                  className="dialog__body"
                  style={{
                    position: "relative",
                    paddingBottom: "5px",
                    height: "97%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <div className="chat-box-blank">
                      <div className="chat-icon-center">
                        <img
                          className="chat-icon"
                          // src={chatIcon}
                          src="/Chat box.png"
                          alt="chat icon"
                          width={100}
                        />
                      </div>
                      <p className="ticket-text">Select a ticket</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpSupport;
