import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./login.css";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { sessionCartApi } from "./redux/features/cart/sessionCartSlice";
import { encryptFields } from "./utils/kycStatus";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const siteKey = process.env.REACT_APP_SITE_KEY;

  const token = sessionStorage.getItem("token");

  const [index, setIndex] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpdata, setOtpdata] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState("");

  const handlePhoneSubmit = async (event) => {
    event.preventDefault();
    // Execute reCAPTCHA v3================================
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(siteKey, {
          action: "submit",
        })
        .then(async (token) => {
          console.log("<<<<<<<<<<<<<", token);
          // handleReCAPTCHAResponse(token);
          if (phoneNumber === "") {
            setErrors("Phone number cannot be empty.");
            return;
          } else if (phoneNumber.length !== 10) {
            setErrors("Phone number must be 10 digits.");
            return;
          } else if (!termsAccepted) {
            setErrors("Please accept terms & conditions");
            return;
          } else {
            setErrors("");
          }

          if (
            phoneNumber === "8171992625" ||
            phoneNumber === "8630844146" ||
            phoneNumber === "9999857854" ||
            phoneNumber === "9915339557" ||
            phoneNumber === "7985351423"
          ) {
            setShowOtpInput(true);
            return;
          }

          try {
            // const url = `${process.env.REACT_APP_BASE_URL}/customUser/register";
            const url = `${process.env.REACT_APP_BASE_URL}/customUser/verification`;
            const headers = {
              "Content-Type": "application/json",
              Accept: "application/json",
            };

            const response = await fetch(url, {
              method: "POST",
              headers: headers,
              body: JSON.stringify({ phone: phoneNumber }),
            });

            toast.success("OTP sent successfully");

            const data = await response.json();
            setOtpdata(data.data[0].otp);
            setShowOtpInput(true);
            setSeconds(60);
          } catch (err) {
            console.log("Error connecting to API", err);
          }
        })
        .catch(() => {
          // setAuthError("reCAPTCHA verification failed. Please try again.");
        });
    });
  };

  const onOtpSubmit = async (otp) => {
    if (!termsAccepted) {
      alert("Please accept the terms & conditions");
      return;
    }

    // Execute reCAPTCHA v3
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(siteKey, {
          action: "submit",
        })
        .then(async (token) => {
          // handleReCAPTCHAResponse(token);

          try {
            const url = `${process.env.REACT_APP_BASE_URL}/customUser/register`;
            const headers = {
              "Content-Type": "application/json",
              Accept: "application/json",
            };
            const reqBody = {
              phone: phoneNumber,
              otp: otp,
            };

            const response = await fetch(url, {
              method: "POST",
              headers: headers,
              body: JSON.stringify(reqBody),
            });

            const data = await response.json();

            const { email, dob, name, phone, _id } = data?.data?.[0] || {};

            if (email || dob || name || phone || _id) {
              // Prepare the userDetails object
              const userDetails = {
                email,
                dob,
                name,
                phone,
                _id,
                pan: false,
                bank: false,
                address: false,
              };

              const fieldsToEncrypt = ["pan", "bank", "address"];

              const encryptedUserDetails = encryptFields(
                userDetails,
                fieldsToEncrypt
              );

              localStorage.setItem(
                "userDetails",
                JSON.stringify(encryptedUserDetails)
              );
            }

            if (data.data && data.data.length > 0 && data.data[0].jwt_token) {
              // console.log("Token:", data.data[0].jwt_token);
              sessionStorage.setItem("token", data.data[0].jwt_token);
              sessionStorage.setItem("isLOGIN", data.data[0].jwt_token);
              setShowOtpInput(false);

              const sessionCart = JSON.parse(sessionStorage.getItem("cart")); // Parse the JSON string into an array
              if (sessionCart) {
                const payload = { carts: sessionCart };

                // console.log("DISHANT SESSION CART++++++++++++++", payload);
                dispatch(sessionCartApi(payload));

                sessionStorage.removeItem("cart");
              }

              updateFcmToken(data.data[0].jwt_token);

              // dispatch(getProfileApi);

              navigate("/account");
            } else {
              console.log("Error: JWT token not found in response");
            }
          } catch (err) {
            console.log("Error connecting to API", err);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const updateFcmToken = async (jwt_token) => {
    try {
      const fcmToken = localStorage.getItem("fcmToken");
      if (!fcmToken) throw new Error("FCM token not found");

      const url = `${process.env.REACT_APP_BASE_URL}/updateFCM`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${jwt_token}`, // Add Bearer token here
      };
      const reqBody = { fcmToken };

      const response = await axios.post(url, reqBody, { headers });
      // console.log("FCM API Response:", response.data);
    } catch (error) {
      console.log("Error updating FCM token:", error);
    }
  };

  // otp fields code

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // Allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // Move to next input if current field is filled and it's not the last input
    if (value && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }

    // if (index === 5 && newOtp.every((digit) => digit !== "")) {
    //   // Delay the submission to ensure state update is complete
    //   setTimeout(() => {
    //     handleSubmit(); // Automatically submit the form
    //   }, 0);
    // }
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    const combinedOtp = otp.join("");

    if (
      phoneNumber === "8171992625" ||
      phoneNumber === "8630844146" ||
      phoneNumber === "9999857854" ||
      phoneNumber === "9915339557" ||
      phoneNumber === "7985351423"
    ) {
      if (combinedOtp === "123456") {
        onOtpSubmit(combinedOtp);
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } else {
      if (combinedOtp.length === 6) {
        if (combinedOtp === otpdata) {
          onOtpSubmit(combinedOtp);
        } else {
          toast.error("Invalid OTP. Please try again.");
        }
      } else {
        toast.error("Please fill all OTP fields");
      }
    }
  };

  const handleClick = (index) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0 && inputRefs.current[index - 1]) {
        // Move focus to the previous input field on backspace
        inputRefs.current[index - 1].focus();
      } else if (index === 0) {
        // Clear all fields if backspace is pressed on the first input field
        setOtp(new Array(6).fill(""));
        inputRefs.current[0].focus();
      }
    }
  };

  const [seconds, setSeconds] = useState(null);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [seconds]);

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", height: "100vh" }}
      >
        <Carousel
          className="login-carousel"
          controls={false}
          indicators={false}
          interval={3000}
          pause={false}
          style={{
            width: "60%",
            backgroundColor: "#DCDAFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Carousel.Item>
            <img
              className="d-block w-100 img-height"
              src="/login1.png"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 img-height"
              src="/login2.png"
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 img-height"
              src="/login3.png"
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            id="loginContainer"
            style={{
              color: "#4A2A7D",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            <h2 className="text-center">Get Started</h2>
            <h6 className="mb-3 text-center" style={{ fontSize: "0.9rem" }}>
              Enter your phone number to get an OTP to login to your account.
            </h6>
            {!showOtpInput ? (
              <Form
                id="demo-form"
                onSubmit={handlePhoneSubmit}
                className="formBody text-dark"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                  }}
                >
                  <Form.Control
                    size="sm"
                    className="number-code-input"
                    style={{ width: "20%" }}
                    type="text"
                    value="+(91)"
                    readOnly
                  />

                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Control
                      type="tel"
                      pattern="[0-9]*"
                      placeholder="Enter mobile no."
                      value={phoneNumber}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setPhoneNumber(e.target.value);
                        }
                      }}
                      className="placeholder-purple"
                      style={{
                        width: "100%",
                        color: "#4D16A5",
                        border: "1px solid #4D16A5",
                      }}
                      maxLength={10}
                    />
                    {errors && (
                      <div style={{ color: "red", fontSize: "0.8rem" }}>
                        {errors}
                      </div>
                    )}
                  </Form.Group>
                </div>

                <div
                  className="d-flex justify-content-center"
                  style={{ color: "#4D16A5" }}
                >
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label={
                      <span
                        className="purple-text"
                        style={{ fontSize: "0.8rem", alignItems: "center" }}
                      >
                        Please accept our terms & conditions
                      </span>
                    }
                    className="rounded-checkbox bold-radio"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    data-sitekey={siteKey}
                    data-callback="onSubmit"
                    data-action="submit"
                    // className="rounded-pill btn-change7"
                    style={{
                      backgroundColor: "#4a2a7d",
                      fontWeight: "bold",
                      fontSize: "20px",
                      alignItems: "center",
                      color: "white",
                      padding: "6px 70px",
                      borderRadius: "30px",
                      marginTop: "10px",
                      border: "none",
                    }}
                    // disabled={!termsAccepted}
                  >
                    Send OTP
                  </button>
                </div>
              </Form>
            ) : (
              <div className="col-12">
                <form id="demo-form" onSubmit={handleSubmit}>
                  <div className="col-12">
                    <div className="d-flex justify-content-center mb-3">
                      {otp.map((value, index) => (
                        <input
                          key={index}
                          type="tel"
                          autoFocus={index === 0}
                          ref={(input) => (inputRefs.current[index] = input)}
                          value={value}
                          onChange={(e) => handleChange(index, e)}
                          onClick={() => handleClick(index)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          className="otpInput text-dark"
                          style={{
                            border: "2px solid #4D16A5",
                            borderRadius: "5px",
                            width: "40px",
                            height: "40px",
                            textAlign: "center",
                            marginRight: "5px",
                          }}
                          maxLength="1"
                        />
                      ))}
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        width: "100%",
                        maxWidth: "300px",
                        margin: "auto",
                        gap: "1rem",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          color: "#4D16A5",
                        }}
                        disabled={seconds > 0}
                        onClick={handlePhoneSubmit}
                      >
                        Resend
                      </button>
                      <div>{seconds}</div>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <button
                        type="submit"
                        data-sitekey={siteKey}
                        data-callback="onSubmit"
                        data-action="submit"
                        // className="rounded-pill btn-change7"
                        // style={{ marginLeft: "0", backgroundColor: "#4a2a7d" }}
                        style={{
                          backgroundColor: "#4a2a7d",
                          fontWeight: "bold",
                          fontSize: "20px",
                          alignItems: "center",
                          color: "white",
                          padding: "6px 70px",
                          borderRadius: "30px",
                          marginTop: "10px",
                          border: "none",
                        }}
                      >
                        Submit OTP
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <img src="/loginFeature1.png" width="20%" />
            <img src="/loginFeature2.png" width="20%" />
            <img src="/loginFeature3.png" width="20%" />
            <img src="/loginFeature4.png" width="20%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
