import React, { useState } from "react";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import "./NavbarMobile.css";
import { NavLink } from "react-router-dom";
import { IoIosArrowDown, IoIosMenu } from "react-icons/io";
import { IoPersonSharp } from "react-icons/io5";

const sidebarLinks = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  // {
  //   name: "Digital Saving",
  //   link: "/dashboard/buygold",
  // },
  {
    name: "Digital Gold",
    link: "/digital-gold",
  },
  {
    name: "Digital Silver",
    link: "/digital-silver",
  },
  {
    name: "Shop",
    link: "/shop",
  },
  {
    name: "Contact Us",
    link: "/contact-us",
  },
  {
    name: "Blogs",
    link: "/blogs",
  },
];

const ResponsiveNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = React.useState(null);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="responsive_navbar">
      <Tooltip title="Menu">
        <IconButton onClick={toggleNavbar} size="small" sx={{ ml: 1 }}>
          <div
            style={{
              padding: "5px",
              backgroundColor: "#DCDAFF",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IoIosMenu size={25} color="#4a2a7d" />
          </div>
        </IconButton>
      </Tooltip>

      <div className={`side-nav ${isOpen ? "open" : ""}`}>
        <div
          style={{ color: "white", fontSize: "40px" }}
          onClick={toggleNavbar}
          className="close-button"
        >
          <CloseIcon fontSize="large" color="#4a2a7d" />
        </div>
        <nav
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {sidebarLinks.map((item, index) => (
            <NavLink
              to={item.link}
              onClick={() => setIsOpen(false)}
              key={index}
              className={({ isActive }) =>
                isActive ? "active-link" : "sidebar-link"
              }
            >
              <ListItem
                disablePadding
                sx={{ display: "flex", justifyContent: "center" }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <ListItemButton>
                  <div
                    style={{
                      margin: "0px",
                      fontSize: "1.5rem",
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {item.name}
                    {item.name == "Digital Saving" && <IoIosArrowDown />}
                  </div>
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default ResponsiveNavbar;
