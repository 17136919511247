import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ref, onValue, runTransaction } from "firebase/database";
import { database } from "../../notifications/firebase";
import { Helmet } from "react-helmet";

const ViewBlog = () => {
  const { blogId , title_url} = useParams();
  const [blogDetails, setBlogDetails] = useState([]);
  const [viewCount, setViewCount] = useState(0);

  const [post, setPost] = useState([]);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    fetchBlogDetails(title_url);
  }, [title_url]);

  const fetchBlogDetails = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin_panel/blog_Details/${title_url}`
      );
      console.log(response.data.data, ">>>>>>>>>>>>");
      setBlogDetails(response.data.data);
      setPost(response.data.data[0]);
    } catch (error) {
      console.log("Failed to fetch blog details.");
    }
  };

  useEffect(() => {
    // Increment the view count when the page loads
    const pageViewsRef = ref(database, `pageViews/${blogId}`);
    runTransaction(pageViewsRef, (current) => (current || 0) + 1);

    // Fetch the current view count
    const handleValueChange = (snapshot) => {
      const count = snapshot.val();
      setViewCount(count || 0);
    };

    // Set up the listener
    const unsubscribe = onValue(pageViewsRef, handleValueChange);

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [blogId]);

  // const metaKeywords = post?.title
  //   ? post.title
  //       .replace(/[^\w\s]/g, "")
  //       .replace(/\s+/g, "-")
  //       .toLowerCase()
  //   : "";

  return (
    <>
      <div className="blog-content-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Batuk | ${post.title}`}</title>
          <meta name="description" content={post.description} />
          <meta name="keywords" content={post.metatag} />{" "}
          <link
            rel="canonical"
            href={`https://partner.batuk.in/blog/${post.title_url}`}
          />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.description} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={post.image} />
        </Helmet>
        {blogDetails.length > 0 &&
          blogDetails.map((blog, index) => (
            <div key={index} className="blog-content">
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </div>
          ))}
      </div>
      {/* <p>Number of views: {viewCount}</p> */}
    </>
  );
};

export default ViewBlog;
