import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomCoinCard from "../../components/CustomCoinCard";
import Loader from "../../components/Loader";
import { Button } from "@mui/material";
import BackButton from "../../components/UI/BackButton";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer.jsx";
import { FaShoppingCart } from "react-icons/fa";
import "./Products.css";

const Products = () => {
  const { t } = useTranslation();
  const { goldProducts, silverProducts } = t("productsPage");

  const navigate = useNavigate();
  const location = useLocation();

  const [metaltype, setMetaltype] = useState(
    location.state.metaltype || "gold"
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const Token = sessionStorage.getItem("token");

  const [viewproduct, setviewproduct] = useState(true);

  const [sumdata, setSumdata] = useState([]);

  const [sortedSilverCoins, setSortedSilverCoins] = useState([]);

  let coinData = "";

  useEffect(() => {
    callAPI();
  }, []);

  const callAPI = async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/augmont/products?page=1&count=30`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();
      coinData = data;

      const goldCoins =
        data?.data?.[0]?.result?.data?.filter(
          (item) => item.metalType === "gold"
        ) || [];

      const sortedGoldCoins = goldCoins.sort(
        (a, b) => parseFloat(a.productWeight) - parseFloat(b.productWeight)
      );

      setSumdata(sortedGoldCoins);

      //-----------------------SORT SILVER COINS-------------------------------

      const silverCoins =
        data?.data?.[0]?.result?.data?.filter(
          (item) => item.metalType === "silver"
        ) || [];

      const sortedSilverCoins = silverCoins.sort(
        (a, b) => parseFloat(a.productWeight) - parseFloat(b.productWeight)
      );

      setSortedSilverCoins(sortedSilverCoins);
    } catch (error) {
      console.error("Error connecting to API", error);
    } finally {
      setLoading(false);
    }
  };

  const [particulardata, setparticulardata] = useState(null);

  const handleCoinClick = (item) => {
    setparticulardata(item);
    setviewproduct(false);
  };

  return (
    <>
      {viewproduct ? (
        <div className="rounded">
          <BackButton link="/dashboard/buygold" />
          <div
            className="row mx-auto d-flex justify-content-center"
            style={{
              color: "#4A2A7D",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h2 style={{ margin: "20px 0" }}>
              {metaltype == "gold" ? goldProducts : silverProducts}
            </h2>
            {loading ? (
              <div style={{ height: "90vh" }}>
                <Loader />
              </div>
            ) : (
              <div className="coin-products-container">
                {(metaltype === "gold" ? sumdata : sortedSilverCoins).map(
                  (item, index) => (
                    <Link
                      to="/dashboard/productDetails"
                      key={index}
                      style={{
                        boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        cursor: "pointer",
                        width: "100%",
                        borderRadius: "5px",
                        padding: "8px",
                      }}
                      state={item}
                    >
                      <div>
                        <CustomCoinCard
                          img={item?.productImages?.[0]?.url}
                          qty={item?.productWeight}
                          name={item?.name}
                          price={item?.basePrice}
                          click={() => {
                            handleCoinClick(item);
                          }}
                        />
                      </div>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#472878",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#472878",
                          },
                        }}
                        endIcon={<FaShoppingCart />}
                        fullWidth
                      >
                        buy now
                      </Button>
                    </Link>
                  )
                )}
              </div>
            )}
            <Footer />
          </div>
        </div>
      ) : (
        <>{/* <CoinDetial data={particulardata} /> */}</>
      )}
    </>
  );
};

export default Products;
