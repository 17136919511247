import CryptoJS from "crypto-js";

export const encryptFields = (data, fieldsToEncrypt) => {
    console.log("ENCRYPT DATA==============",data)
  const encryptedData = { ...data };
  const key = process.env.REACT_APP_CRYPTO_SECRET_KEY || "default-secret-key";

  fieldsToEncrypt.forEach((field) => {
    if (encryptedData[field] !== undefined) {
      encryptedData[field] = CryptoJS.AES.encrypt(
        JSON.stringify(encryptedData[field]),
        key
      ).toString();
    }
  });

  return encryptedData;
};

export const decryptFields = (data, fieldsToDecrypt) => {
  const decryptedData = { ...data };
  const key = process.env.REACT_APP_CRYPTO_SECRET_KEY || "default-secret-key";

  fieldsToDecrypt.forEach((field) => {
    if (decryptedData[field] !== undefined) {
      try {
        const bytes = CryptoJS.AES.decrypt(decryptedData[field], key);
        decryptedData[field] = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (error) {
        console.error(`Error decrypting field "${field}":`, error);
        decryptedData[field] = null; // Assign null if decryption fails
      }
    }
  });

  return decryptedData;
};
