import React, { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCountdown } from "react-countdown-circle-timer";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getGoldApi } from "../../redux/features/goldSlice.js";
import {
  goldAndSliverBuyApi,
  resetState,
} from "../../redux/features/goldAndSilver/goldAndSilverBuySlice.js";
import Loader from "../../components/Loader.jsx";
import { Button } from "@mui/material";

function BuySummary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isGold = location.pathname.includes("gold") ? "Gold" : "Silver";

  useEffect(() => {
    const login = sessionStorage.getItem("token");
    if (!login) {
      navigate("/");
    }
  }, []);

  const { t } = useTranslation();
  const { orderSummary, gst, totalAmt, priceExpire, buyBtn } = t(
    "goldOrderSummaryPage"
  );
  const { goldWeight, goldRate, goldValue } = t(
    "goldOrderSummaryPage.goldSummary"
  );

  const Token = sessionStorage.getItem("token");

  const data = location.state;

  console.log("locationnnnnnnnnnnnnnn", data);
  const [pannum, setpanum] = useState("");
  const [billing, setbilling] = useState("");
  const [razorpayResponse, setRazorpayResponse] = useState(null);

  const Goldweight = data.data.data[0].totalQuantity;
  const Goldrate = data.data.data[0].goldPrice;
  const GoldValue = data.data.data[0].amount;
  const GST = data.data.data[0].gst;
  const TotalAmount = data.data.data[0].amountAfterTax;
  const mtd = data.data.data[0].merchantTransactionId;
  const mt = data.data.data[0].metalType;
  const mClick = data.clickFirst;

  const [isPanBAddress, setisPanBAddress] = useState(null);
  useEffect(() => {}, []);
  // ----------------timer--------------
  const {
    path,
    pathLength,
    stroke,
    strokeDashoffset,
    remainingTime,
    elapsedTime,
    size,
    strokeWidth,
  } = useCountdown({ isPlaying: true, duration: 7, colors: "#abc" });
  // -----------------------------------------------

  // const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const renderTime = ({ remainingTime }) => {
    return <div>{remainingTime}</div>;
  };

  useEffect(() => {
    GetProfileAPI();
  }, []);

  const [profileApiData, setProfileApiData] = useState("");

  const GetProfileAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/getProfile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      // console.log(
      //   "PROFILE API DATA=====================",
      //   response.data.data[0].kyc_status
      // );
      setProfileApiData(response?.data?.data[0]);
      const Profiledata = response.data;
      const maindata = Profiledata.data[0];
      setbilling(maindata.hasBillingaddress_id);
      Profiledata.data?.map((item) => {
        // console.log("item of billing address from profile", item);
        setpanum(item.pan_number);

        // console.log("pan number sandy sir", item.pan_number);
        if (item.pan_number !== null && item.hasBillingaddress_id !== false) {
          setisPanBAddress(true);
        } else {
          setisPanBAddress(false);
        }
      });
      // console.log("Get Profile API Response", Profiledata);
      //   let PanNumber = Profiledata.data[0].pan_number;
      //     let billingAddressId = Profiledata.data[0].hasBillingaddress_id;
      //     console.log("PanNumber",PanNumber);
      //     console.log("billingAddressId",billingAddressId);
      //   if (PanNumber !== "" && billingAddressId !== false) {
      //     setisPanBAddress(true);
      //   }  else {
      //     setisPanBAddress(false);
      //   }
    } catch (error) {
      // console.error("Error connecting to API in getprofile function", error);
    }
  };

  const PanvalidRedirect = () => {
    if (pannum === null) {
      // handlePayment();
      navigate("/dashboard/pancard");
    } else if (pannum !== null && billing === false) {
      navigate("/dashboard/billing");
    } else {
      handlePayment();
      // navigate('/dashboard/pancard');
    }
  };

  const callGetBuyAPI = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/augmont/buy`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const requestBody = {
        merchantTransactionId: mtd,
        metalType: mt,
      };
      // console.log("mtd", mtd);
      // console.log("mt", mt);
      if (mClick === "A") {
        requestBody["amount"] = TotalAmount;
      } else if (mClick === "Q") {
        requestBody["quantity"] = Goldweight;
      } else {
        requestBody["amount"] = TotalAmount;
      }
      // console.log("TotalAmount", TotalAmount);
      // console.log("Goldweight", Goldweight);
      // console.log("TotalAmount", TotalAmount);
      // console.log("requestBody", requestBody)
      // console.log("headers", headers)
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      console.log("BuyGet after success Api response data is=", data);
      // var transec = data.data[0].result.data.transactionId;
      return data;
    } catch (error) {
      // console.error("Error connecting to API in buy api", error);
      throw error;
    }
  };

  const buyApiLoading = useSelector(
    (state) => state.goldAndSilverBuySlice.status
  );
  const buyApiResponse = useSelector(
    (state) => state.goldAndSilverBuySlice.data
  );

  //   console.log("BUY API:::::::::::::", buyApiResponse);

  const { totalAmount, transactionId } =
    Array.isArray(buyApiResponse?.data) && buyApiResponse?.data.length > 0
      ? buyApiResponse?.data[0]?.result?.data || {}
      : {};

  // console.log("DESTRUCTURE VALUE===", totalAmount);

  const handleOpenRazorpay = async (data) => {
    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: Number(data.amount),
        currency: data.currency,
        name: "Bharat Batuk Pvt.ltd.",
        description: "XYZ",
        order_id: data.id,
        handler: async function (response) {
          setRazorpayResponse(response);

          const requestBody = {
            merchantTransactionId: mtd,
            metalType: mt,
          };

          if (mClick === "A") {
            requestBody["amount"] = TotalAmount;
          } else if (mClick === "Q") {
            requestBody["quantity"] = Goldweight;
          } else {
            requestBody["amount"] = TotalAmount;
          }

          await dispatch(goldAndSliverBuyApi(requestBody));
          // if (totalAmount) {
          //   Swal.fire({
          //     title: "Payment Successful",
          //     html: `Transaction Number: ${transactionId}<br/><br/>Amount: ₹${totalAmount}`,
          //     icon: "success",
          //   });
          //   navigate("/dashboard/invoice", {
          //     state: {
          //       buydata: buyApiResponse || "",
          //     },
          //   });
          // }

          // callGetBuyAPI()
          //   .then((buydata) => {
          //     const am = buydata.data[0].result.data.totalAmount;
          //     const tranid = buydata.data[0].result.data.transactionId;
          //     Swal.fire({
          //       title: "Payment Successful",
          //       html: `
          //                   Transaction Number: ${tranid}<br/><br/>
          //                   Amount: ₹${am}
          //                 `,

          //       icon: "success",
          //     }).then((d) => {
          //       navigate("/dashboard/invoice", {
          //         state: {
          //           buydata: buydata,
          //         },
          //       });
          //     });
          //   })
          //   .catch((error) => {
          //     console.log("error++++++++++", error);
          //   });
        },
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error handling Razorpay", error);
      throw error;
    }
  };

  useEffect(() => {
    if (totalAmount) {
      Swal.fire({
        title: "Payment Successful",
        html: `Transaction Number: ${transactionId}<br/><br/>Amount: ₹${totalAmount}`,
        icon: "success",
      });
      navigate(
        isGold == "Gold" ? "/dashboard/invoice" : "/dashboard/silverinvoice",
        {
          state: {
            buydata: buyApiResponse || "",
          },
        }
      );
      dispatch(resetState());
    }
  }, [totalAmount]);

  const handlePayment = async () => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Token}`,
    };
    try {
      const paymentData = { TotalAmount, transaction_id: mtd };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/Razorpay`,
        paymentData,
        { headers }
      );

      // console.log("RAZORPAY API RESPONSE===================", response);
      await handleOpenRazorpay(response.data.data);
    } catch (error) {
      console.error("Error handling payment:", error);
    }
  };

  useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <>
      {buyApiLoading == "loading" ? (
        <div style={{ height: "90vh" }}>
          <Loader />
        </div>
      ) : !buyApiResponse.error ? (
        <div className="container-fluid" style={{ height: "auto" }}>
          <div className="row d-flex justify-content-between">
            <div
              style={{
                height: "auto",
                borderRadius: "10px",
                boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)",
              }}
            >
              <h2
                className="m-2 fw-bold text-center mb-3"
                style={{ color: "#4A2A7D" }}
              >
                {orderSummary}
              </h2>
              {/* <div className="row d-flex justify-content-around" >

                        <div className='col  d-flex m-2 p-1' style={{ border: "2px solid #880E4F", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(128, 0, 128, 0.5)" }}>
                            <div className='col-md-2 ' >
                                <img src={Goldlogo} alt="here is logo" className='img-fluid' style={{ height: "80px" }} />
                            </div>
                            <div className='col-md-4   p-2 ml-5 ' style={{ height: "auto" }}>
                                <h2 className='text-center fw-bold' style={{ color: "#880E4F" }}>Augmont</h2>
                                <p className='text-center' style={{ color: "#880E4F" }}>Gold Weight: {Goldweight} gm</p>
                            </div>
                        </div>
                    </div> */}

              <div className="row">
                <table className="table table-borderless">
                  <tbody style={{ color: "#4A2A7D" }}>
                    <tr>
                      <td></td>

                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {isGold} Weight AUGMONT (gm)
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {Goldweight}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {isGold} Rate per gram (₹)
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {isGold == "Gold" ? Goldrate : GoldValue}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {isGold} Value Of {Goldweight}/gm (&#8377;)
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {GoldValue}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {gst} (&#8377;)
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style={{ color: "#4A2A7D", fontSize: "20px" }}
                        className=" fs-5"
                      >
                        {GST}
                      </td>
                    </tr>
                    {/* <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr> */}
                    <tr>
                      <td></td>
                      <td className="fw-bold fs-5" style={{ color: "#4A2A7D" }}>
                        {totalAmt} (&#8377;)
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="fw-bold fs-5" style={{ color: "#4A2A7D" }}>
                        {TotalAmount}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="d-flex justify-content-center mb-3"
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <span className=" mt-2 mr-3 h5" style={{ color: "#4A2A7D" }}>
                  {priceExpire}
                </span>{" "}
                <CountdownCircleTimer
                  isPlaying
                  size={60}
                  strokeWidth={10}
                  duration={180} // 3 minutes in seconds
                  colors={["#4A2A7D", "#4A2A7D", "#4A2A7D", "#4A2A7D"]}
                  colorsTime={[180, 120, 60, 0]}
                  onComplete={() => {
                    navigate(-1); // Redirect to the previous history page
                    return { shouldRepeat: false }; // Ensure the timer doesn't repeat
                  }}
                >
                  {({ remainingTime }) => {
                    const minutes = Math.floor(remainingTime / 60);
                    const seconds = remainingTime % 60;
                    const formattedTime = `${minutes}:${seconds
                      .toString()
                      .padStart(2, "0")}`;
                    return (
                      <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {formattedTime}
                      </div>
                    );
                  }}
                </CountdownCircleTimer>
              </div>

              <Footer />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "2rem auto",
                }}
              >
                {profileApiData?.kyc_status == 1 &&
                profileApiData?.billAddressUser != null ? (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#4a2a7d",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#4a2a7d",
                      },
                    }}
                    onClick={handlePayment}
                  >
                    Buy now
                  </Button>
                ) : (
                  <Link to="/dashboard/ProfileSetting">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#4a2a7d",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#4a2a7d",
                        },
                      }}
                    >
                      Complete KYC
                    </Button>
                  </Link>
                )}
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            color: "#4a2a7d",
          }}
        >
          <img src="/loadingTransaction.png" alt="loading" width="30%" />
          <h2>Uh-oh!...</h2>
          <p>
            It is taking longer than expected. <br /> Don't worry your money is
            Safe. <br /> We will update your Gold balance soon.
          </p>
          {/* <Link to="/account">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4a2a7d",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4a2a7d",
                },
              }}
            >
              Home
            </Button>
          </Link> */}
        </div>
      )}
    </>
  );
}

export default BuySummary;
